import React from 'react';

import { Helmet } from 'react-helmet';

import { Link } from 'gatsby';
import { SiteConfig } from '../components/config';
import { BackgroundImage } from '../components/images';
import { Head } from '../components/layout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <SiteConfig>
        {({ title, logoSymbolUrl }) => (
          <>
            <Head title={title} />
            <Helmet
              bodyAttributes={{
                class: 'h-404-page',
              }}
            />
            <h1>
              <div>4</div>
              <BackgroundImage url={logoSymbolUrl} />
              <div>4</div>
            </h1>
            <h2>Page Not Found</h2>
            <div>
              <Link to="/">
                <FontAwesomeIcon
                  className="h-return-icon"
                  icon={faLongArrowAltLeft}
                />
                Return to the main page
              </Link>
            </div>
          </>
        )}
      </SiteConfig>
    );
  }
}
